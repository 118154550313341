import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import React from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { graphql, useStaticQuery } from "gatsby";
import WebFooter from "../../images/web-footer.png";
import MobileFooter from "../../images/mobile-footer.png";

const Footer = () => {
  const { width } = useScreenSize();

  const footerIcon = useStaticQuery(graphql`
    query {
      coc: file(name: { eq: "coc" }) {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 30
            width: 1920
            placeholder: BLURRED
            formats: WEBP
          )
        }
      }
      zionism: file(name: { eq: "zionism" }) {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 30
            width: 1920
            placeholder: BLURRED
            formats: WEBP
          )
        }
      }
      awe: file(name: { eq: "awe" }) {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 30
            width: 1920
            placeholder: BLURRED
            formats: WEBP
          )
        }
      }
    }
  `);

  console.log("footerIcon", footerIcon);

  return (
    <footer className="relative mt-[40px] flex flex-col gap-[10px]">
      {/* <div className="flex flex-wrap justify-center items-center">
        <p className="text-[12px] sm:text-[36px]">A campaign envisioned by</p>
        <a
          href="https://cameraoncampus.org/"
          className="flex justify-center mx-3"
        >
          <img
            src={footerIcon.coc.publicURL}
            alt={footerIcon.coc.name}
            className="w-[110px] h-[12px] sm:w-[330px] sm:h-[36px]"
          />
        </a>
        <p className="text-[12px] sm:text-[36px]">. Learn about our other campaigns:</p>
      </div>
      <div className="flex gap-[10px] md:gap-[25px] justify-center items-center">
        <a href="https://zionism.me/">
          <img
            src={footerIcon.zionism.publicURL}
            alt={footerIcon.zionism.name}
            className="md:w-[300px]"
          />
        </a>
        <a href="https://apartheidweekexposed.org/">
          <img
            src={footerIcon.awe.publicURL}
            alt={footerIcon.awe.name}
            className="sm:w-[600px]"
          />
        </a>
      </div> */}
      <img src={width < 1024 ? MobileFooter : WebFooter} alt="" />
      {width < 1024 ? (
        <>
          <a
            href="https://cameraoncampus.org/"
            className="absolute top-[5%] left-[56%] w-[35%] h-[25%]"
          ></a>
          <a
            href="https://zionism.me/"
            className="absolute bottom-[20%] left-[3%] w-[28%] h-[25%]"
          ></a>
          <a
            href="https://apartheidweekexposed.org/"
            className="absolute bottom-[20%] left-[33%] w-[65%] h-[25%]"
          ></a>
        </>
      ) : (
        <>
          <a
            href="https://cameraoncampus.org/"
            className="absolute top-[5%] left-[36%] w-[20%] h-[35%]"
          ></a>
          <a
            href="https://zionism.me/"
            className="absolute bottom-[5%] left-[23%] w-[15%] h-[35%]"
          ></a>
          <a
            href="https://apartheidweekexposed.org/"
            className="absolute bottom-[5%] left-[40%] w-[37%] h-[35%]"
          ></a>
        </>
      )}
    </footer>
  );
};

export default Footer;
