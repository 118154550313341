import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { Location, navigate } from "@reach/router";

const Layout = ({ children }) => {
  const [pathname, setPathname] = useState(window.location.pathname);
  const [show, setShow] = useState(false);

  const query = useStaticQuery(graphql`
    query {
      allContentfulPage(filter: { type: { eq: "country" } }) {
        nodes {
          title
          slug
          id
        }
      }
      allContentfulSocialIcons {
        nodes {
          id
          title
          link
          iconImage {
            gatsbyImageData(
              quality: 40
              cropFocus: CENTER
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  const pages = query.allContentfulPage.nodes;
  const social = query.allContentfulSocialIcons.nodes;

  const [keyword, setKeyword] = useState("");

  return (
    <Location>
      {({ location }) => {
        const searchParams = new URLSearchParams(location.search);
        const myQueryParam = searchParams.get("q");
        const search = (e) => {
          e.preventDefault();
          if (keyword) {
            setShow(false)
            navigate(`/search/?q=${keyword}`);
          }
        };
        return (
          <main className="layout !w-full">
            <section
              style={{
                right: show ? 0 : "-100%",
              }}
              className="flex flex-col gap-[20px] bg-[#173150] w-full sm:w-[50%] md:w-[40%] lg:w-[30%] h-[100vh] overflow-y-auto fixed top-0 right-0 z-50 p-[20px] transition-all"
            >
              <div className="flex justify-between items-center">
                <form
                  onSubmit={search}
                  className="py-2 px-6 rounded-[50px] border-[3px] border-[white] flex items-center"
                >
                  <input
                    defaultValue={myQueryParam}
                    onChange={(e) => setKeyword(e.target.value)}
                    type="text"
                    className="px-2 bg-transparent w-[200px] h-[30px] border-none outline-none text-[16px] text-white"
                  />
                  <StaticImage
                    src="../../images/Magnifying.png"
                    alt="Magnifying"
                    className="h-[20px] w-[20px] md:w-[25px] md:h-[25px]"
                  />
                </form>
                <div
                  className="absolute cursor-pointer w-full right-[20px]"
                  onClick={() => setShow(!show)}
                >
                  <div className="h-[5px] w-[30px] rotate-45 bg-white absolute right-4"></div>
                  <div className="h-[5px] w-[30px] rotate-[-45deg] bg-white absolute right-4"></div>
                </div>
              </div>
              <ul className="flex flex-col mt-[20px]">
                <li
                  onClick={() => setShow(false)}
                  className="text-[20px] border-b-[1px] border-[grey] text-white py-3 px-5"
                >
                  <Link to="/">Home</Link>
                </li>
                <li
                  onClick={() => setShow(false)}
                  className="text-[20px] border-b-[1px] border-[grey] text-white py-3 px-5"
                >
                  <Link to="/about-the-campaign">About The Campaign</Link>
                </li>
                <li
                  onClick={() => setShow(false)}
                  className="text-[20px] border-b-[1px] border-[grey] text-white py-3 px-5"
                >
                  <Link to="/resources">Resources</Link>
                </li>
                {pages.map((page) => (
                  <li
                    onClick={() => setShow(false)}
                    id={page.id}
                    className="text-[20px] border-b-[1px] border-[grey] text-white py-3 px-5"
                  >
                    <Link to={`/${page.slug}`}>{`${page.title}`}</Link>
                  </li>
                ))}
              </ul>
              {/* <div className="flex gap-[15px] justify-center">
                {social?.map((icon) => (
                  <a key={icon.id} href={icon.link}>
                    <GatsbyImage
                      alt={icon.title}
                      image={getImage(icon.iconImage)}
                      className="w-[30px] h-[30px]"
                    />
                  </a>
                ))}
              </div> */}
            </section>
            <div className="overlay !w-full relative">
              <div
                className={`${
                  location.pathname === "/" ||
                  location.pathname === "/resources/"
                    ? "white-bg-overlay"
                    : "white-bg-overlay-footer"
                } p-[30px] sm:px-[9.6rem] sm:py-[6.3rem] !w-full`}
              >
                <Header setShowSidebar={setShow} showSidebar={show} />
                {children}
                <Footer />
              </div>
            </div>
          </main>
        );
      }}
    </Location>
  );
};

export default Layout;
