import { Location, navigate } from "@reach/router";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { useState } from "react";

const Header = ({ setShowSidebar, showSidebar }) => {
  const [keyword, setKeyword] = useState("");
  const query = useStaticQuery(graphql`
    query {
      allContentfulSocialIcons {
        nodes {
          id
          title
          link
          iconImage {
            gatsbyImageData(
              quality: 40
              cropFocus: CENTER
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);
  const socialIcons = query.allContentfulSocialIcons.nodes;

  return (
    <header className="flex items-center justify-between">
      <div className="hidden md:flex gap-[5px] md:gap-[10px] lg:gap-[20px] xl:gap-[24px]">
        {socialIcons?.map((image) => (
          <a key={image.id} href={image.link}>
            <GatsbyImage
              alt={image.title}
              image={getImage(image.iconImage)}
              className="w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] xl:w-[40px] xl:h-[40px]"
            />
          </a>
        ))}
      </div>
      <h1 className="mt-[5px] text-[30px] lg:text-[35px] xl:text-[50px] text-white text-center font-bold">
        <Link to="/">Mizrahi Stories</Link>
      </h1>
      <div className="flex items-center gap-[2.4rem]">
        <Location>
          {({ location }) => {
            const searchParams = new URLSearchParams(location.search);
            const myQueryParam = searchParams.get("q");
            const search = (e) => {
              e.preventDefault();
              if (keyword) {
                navigate(`/search/?q=${keyword}`);
              }
            };
            return (
              <form
                onSubmit={search}
                className="hidden border-[5px] border-[white] md:flex items-center"
              >
                <input
                  defaultValue={myQueryParam}
                  onChange={(e) => setKeyword(e.target.value)}
                  type="text"
                  className="bg-transparent md:h-[25px] md:w-[120px] lg:w-[150px] lg:h-[30px] xl:w-[180px] xl:h-[35px] border-none outline-none text-[22px] text-white px-2"
                />
                <StaticImage
                  src="../../images/Magnifying.png"
                  alt="Magnifying"
                  className="h-[3rem] w-[3rem]"
                />
              </form>
            );
          }}
        </Location>
        <div
          className="flex flex-col gap-[1.1rem] cursor-pointer"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <div className="w-[30px] h-[5px] md:w-[40px] md:h-[7px] bg-white"></div>
          <div className="w-[30px] h-[5px] md:w-[40px] md:h-[7px] bg-white"></div>
          <div className="w-[30px] h-[5px] md:w-[40px] md:h-[7px] bg-white"></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
