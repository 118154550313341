import { useState, useEffect } from "react";

export default function useScreenSize() {
  const isClient = typeof window === "object"; // Check if window is defined

  const [screenSize, setScreenSize] = useState(
    isClient ? getWindowSize() : { width: 0, height: 0 }
  );

  function getWindowSize() {
    return {
      width: isClient ? window.innerWidth : 0, // Set a default width value
      height: isClient ? window.innerHeight : 0, // Set a default height value
    };
  }

  useEffect(() => {
    if (!isClient) {
      return; // Bail out if window is not defined (e.g., during server-side rendering)
    }

    function handleResize() {
      setScreenSize(getWindowSize());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isClient]);

  return screenSize;
}
